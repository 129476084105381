<template>
  <b-nav vertical>
    <img
      alt="Tecnomodel Logo"
      src="../assets/tecnomodel_white_logo.png"
      class="logo"
    />
    <span v-for="item in menu" :key="item.name">
      <sidebar-element
        :name="item.name"
        :fa-icon="item.faIcon"
        :children="item.children"
      />
    </span>
  </b-nav>
</template>

<script>
import menu from "@/menu.json";
import SidebarElement from "./SidebarElement";

export default {
  name: "Sidebar",
  components: { SidebarElement },
  data() {
    return {
      menu: []
    };
  },
  mounted() {
    this.menu = menu;
  }
};
</script>

<style>
.text-white.nav-item.nav-item a {
  color: white;
}

/*.text-main-color.nav-item.nav-item a { // custom
  color: rgba(255, 153, 0, 1);
}*/

.logo {
  width: 180px;
  padding-bottom: 20px;
}
</style>
