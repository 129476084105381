import Vue from "vue";
import VueRouter from "vue-router";
import Customer from "../views/Customer";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/sales-control",
    name: "salesControl",
    component: () => import("../views/SalesControl.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/customer",
    name: "customer",
    component: Customer,
    meta: { requiresAuth: true }
  },
  {
    path: "/upload",
    name: "upload",
    component: () =>
      import(/* webpackChunkName: "upload" */ "../views/Upload.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: { header: false }
  },
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/print",
    name: "print",
    component: () =>
      import(/* webpackChunkName: "print" */ "../views/print.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/turnover",
    name: "turnover",
    component: () =>
      import(/* webpackChunkName: "turnover" */ "../views/Turnover.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/shipment",
    name: "shipment",
    component: () =>
      import(/* webpackChunkName: "shipment" */ "../views/Shipment.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/shipment/list",
    name: "shipments-list",
    component: () =>
      import(/* webpackChunkName: "shipment" */ "../views/ShipmentsList.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/configuration/work-station",
    name: "work-station",
    component: () => import("../views/WorkStationConfiguration.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/configuration/printer",
    name: "printer",
    component: () => import("../views/PrinterConfiguration.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/embed-page",
    name: "EmbedPage",
    component: () => import("../views/EmbedPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/supply-manager",
    name: "SupplyManager",
    component: () => import("../views/SupplyManager.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    meta: { customHeader: true },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.header === false)) {
    store.commit("SET_HEADER", false);
  } else {
    store.commit("SET_HEADER", true);
  }

  const loggedIn = localStorage.getItem("token");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    window.previousUrl = to.path;
    next("/login");
  }

  next();
});

export default router;
