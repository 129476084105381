<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-card img-height="100" img-alt="Card image" img-left class="mb-3">
        <b-card-text>
          <h3>Federico Sonetti</h3>
          <p><b-icon icon="telephone-fill"></b-icon> 0586 407558</p>
          <p>
            <b-icon icon="envelope-fill"></b-icon> federico.sonetti@gmail.com
          </p>
        </b-card-text>
      </b-card>
      <b-table striped hover :items="orders" :fields="fields">
        <template v-slot:cell(name)="row">
          {{ row.item.name }}
        </template>
        <template v-slot:cell(age)="row">
          {{ row.item.age }}
        </template>
      </b-table>
      <b-tabs content-class="mt-3">
        <b-tab title="First" active>
          <b-table striped hover :items="orders" :fields="fields">
            <template v-slot:cell(name)="row">
              {{ row.item.name }}
            </template>
            <template v-slot:cell(age)="row">
              {{ row.item.age }}
            </template>
          </b-table></b-tab
        >
        <b-tab title="Second"><p>I'm the second tab</p></b-tab>
        <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
      </b-tabs>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "customer",
  data() {
    return {
      fields: ["name", "age"],
      orders: []
    };
  },
  mounted() {
    this.$axios
      .get("/data/order.json")
      .then(response => (this.orders = response.data));
  }
};
</script>
