<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row>
        <b-col class="mb-3" cols="3">
          <!--<h2>Spedizioni: <b-badge variant="primary">37</b-badge></h2>-->
          <b-form-input
            placeholder="n. ordine"
            v-model="orderId"
            ref="order"
            @keyup.enter="getOrder"
            autofocus
          ></b-form-input>
        </b-col>
        <b-col class="mb-3 text-right" cols="9">
          <b-link variant="primary" :to="{ name: 'shipments-list' }"
            >Manifest</b-link
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <template v-if="step === 5">
              <b-row class="mb-3">
                <b-col>
                  <h4>Ordine n. {{ this.orderId }}</h4>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-alert variant="danger" show>{{ errorShipment }}</b-alert>
                </b-col>
              </b-row>
            </template>
            <template v-if="step === 4">
              <b-row class="mb-3">
                <b-col>
                  <h4>Ordine n. {{ this.orderId }}</h4>
                </b-col>
                <b-col class="text-right">
                  <h4>Spedizione processata</h4>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-alert variant="success" show dismissible
                    >La spedizione è stata processata.</b-alert
                  >
                </b-col>
              </b-row>
              <!--<b-row class="mb-3">
                <b-col>
                  <b-button variant="primary" size="lg" block
                  >Procedi con la prossima spedizione</b-button>
                </b-col>
              </b-row>-->
            </template>
            <template v-if="step === 3">
              <b-row class="mb-3">
                <b-col>
                  <h4>Ordine n. {{ this.orderId }}</h4>
                </b-col>
                <b-col class="text-right">
                  <h4>Stampa etichette <font-awesome-icon icon="print" /></h4>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="9">
                  <b-alert variant="danger" show v-if="errorResponse">
                    {{ errorResponse }}</b-alert
                  >
                </b-col>
                <b-col cols="3">
                  <b-button
                    variant="danger"
                    size="lg"
                    @click="deleteShipment"
                    block
                    >elimina spedizione <font-awesome-icon icon="trash"
                  /></b-button>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-card title="Indirizzo">
                    {{ address.firstName }} {{ address.lastName }}
                    <br />
                    {{ address.line1 }} <br />
                    {{ address.line2 }} <br v-if="address.line2" />
                    {{ address.postalCode }} {{ address.city }}
                    {{ address.province }}<br />
                    {{ address.countryName }}
                  </b-card>
                </b-col>
                <b-col> </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-button
                    variant="primary"
                    size="lg"
                    @click="printLabel"
                    block
                    >Stampa etichetta <font-awesome-icon icon="print"
                  /></b-button>
                </b-col>
                <b-col>
                  <b-alert
                    variant="success"
                    :show="printSuccess === true"
                    dismissible
                    >Stampa avvenuta con successo!</b-alert
                  >
                  <b-alert
                    variant="danger"
                    :show="printSuccess === false"
                    dismissible
                    >Si è verifato un problema nella stampa.</b-alert
                  >
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-button
                    variant="primary"
                    size="lg"
                    block
                    :disabled="printSuccess !== true"
                    @click="close"
                    >Chiudi Spedizione <font-awesome-icon icon="truck"
                  /></b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="step === 2">
              <b-row class="mb-3">
                <b-col>
                  <h4>Ordine n. {{ orderId }}</h4>
                </b-col>
                <b-col class="text-right">
                  <h4>
                    Dimensioni e pesi dei colli
                    <font-awesome-icon icon="box" />
                  </h4>
                </b-col>
              </b-row>
              <b-input-group class="mb-3">
                <b-form-input
                  type="text"
                  v-model="boxScan"
                  ref="boxScan"
                  autofocus
                  @keyup.enter="addBox"
                />
              </b-input-group>
              <b-row class="mb-3">
                <b-col>
                  <h3>
                    Colli: <b-badge variant="primary">{{ parcels }}</b-badge>
                  </h3>
                </b-col>
              </b-row>
              <b-row
                v-for="(parcelElement, index) in parcelElements"
                :key="index"
                class="mb-3"
              >
                <b-col cols="2">
                  <b-form-input
                    placeholder="Peso kg"
                    :value="parcelElement.weight"
                    v-model="parcelElements[index].weight"
                    @keyup.enter="editSize"
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    placeholder="Lunghezza cm"
                    :value="parcelElement.length"
                    v-model="parcelElements[index].length"
                    @keyup.enter="editSize"
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    placeholder="Larghezza cm"
                    :value="parcelElement.width"
                    v-model="parcelElements[index].width"
                    @keyup.enter="editSize"
                  ></b-form-input>
                </b-col>
                <b-col cols="2">
                  <b-form-input
                    placeholder="Altezza cm"
                    :value="parcelElement.height"
                    v-model="parcelElements[index].height"
                    @keyup.enter="editSize"
                  ></b-form-input>
                </b-col>
                <b-col cols="1">
                  <b-button @click="copyParcel(index)" block variant="primary"
                    ><font-awesome-icon icon="copy"
                  /></b-button>
                </b-col>
                <b-col cols="1">
                  <b-button @click="removeParcel(index)" block variant="danger"
                    ><font-awesome-icon icon="trash"
                  /></b-button>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-button @click="insertParcel()" variant="primary">
                    <font-awesome-icon icon="plus"
                  /></b-button>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-card title="Indirizzo">
                    {{ address.firstName }} {{ address.lastName }}
                    <br />
                    {{ address.line1 }} <br />
                    {{ address.line2 }} <br v-if="address.line2" />
                    {{ address.postalCode }} {{ address.city }}
                    {{ address.province }}<br />
                    {{ address.countryName }}
                  </b-card>
                </b-col>
                <b-col class="text-right">
                  <b-form-checkbox v-model="labelPdf" size="lg" switch>
                    <b>PDF</b>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-alert variant="danger" show v-if="errorResponse">
                    {{ errorResponse }}</b-alert
                  >
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    @click="sendData"
                    :disabled="confirmButtonDisabled"
                    >Conferma e Invia dati al corriere</b-button
                  >
                </b-col>
              </b-row>
            </template>
            <template v-if="step === 1">
              <b-row class="mb-3">
                <b-col>
                  <h4>Ordine n. {{ orderId }}</h4>
                </b-col>
                <!--<b-col>
                  <b-button variant="primary" class="mr-3"
                    ><font-awesome-icon icon="print"
                  /></b-button>
                  <b-button variant="primary"
                    ><font-awesome-icon icon="pause"
                  /></b-button>
                </b-col>-->
                <b-col class="text-right">
                  <h4>
                    Verifica articoli <font-awesome-icon icon="barcode" />
                  </h4>
                </b-col>
              </b-row>
              <b-input-group class="mb-3">
                <b-form-input
                  type="text"
                  v-model="barcode"
                  ref="barcode"
                  @keyup.enter="addItem"
                  autofocus
                />
              </b-input-group>
              <b-alert
                :show="orderNote !== null"
                variant="warning"
                class="text-pre-line"
                >{{ orderNote }}</b-alert
              >
              <b-alert variant="danger" show v-if="errorResponse">
                {{ errorResponse }}</b-alert
              >
              <b-alert :show="incorrectBarcodes.length > 0" variant="danger"
                ><div class="mb-1">
                  <strong
                    >Per questo ordine sono stati letti i codice a barre:
                    <ul>
                      <li v-for="barcode in incorrectBarcodes" :key="barcode">
                        {{ barcode }}
                      </li>
                    </ul>
                    che non sono richiesti. Assicurati di rimuovere gli articoli
                    errati e scegli un opzione.</strong
                  >
                </div>
                <b-row>
                  <b-col>
                    <b-button
                      variant="danger"
                      size="lg"
                      @click="resetIncorrectBarcodes"
                      block
                      >Ho rimosso gli articoli errati</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      variant="warning"
                      size="lg"
                      block
                      @click="resetAllQuantity"
                      >Ripeti verifica per tutti gli articoli</b-button
                    >
                  </b-col>
                </b-row></b-alert
              >
              <b-alert
                :show="
                  fails.filter(function(element) {
                    return element !== undefined;
                  }).length > 0
                "
                variant="danger"
                ><div class="mb-1">
                  <strong
                    >Sono stati aggiunti i seguenti articoli con quantità
                    eccedente a quella richiesta:
                    <ul>
                      <li
                        v-for="(fail, index) in fails.filter(function(element) {
                          return element !== undefined;
                        })"
                        :key="index"
                      >
                        {{ fail.name }}
                        x <strong>{{ fail.quantity }} </strong> pezzi
                      </li>
                    </ul>
                    Se gli articoli sono fisicamente presenti in quantità
                    maggiore assicurati di toglierli. Puoi rimuovere gli errori
                    correggendo le quantità oppure ripetendo la verifica per
                    questi articoli.</strong
                  >
                </div>
                <b-button
                  variant="warning"
                  size="lg"
                  block
                  @click="resetQuantity()"
                  >Ripeti verifica per gli articoli eccedenti</b-button
                ></b-alert
              >
              <b-alert
                :show="success && incorrectBarcodes.length === 0"
                variant="success"
                ><div class="text-center mb-1">
                  <strong
                    >Corrispondenza articoli completata con successo!</strong
                  >
                </div>
                <b-button variant="success" block size="lg" @click="step = 2"
                  >Continua</b-button
                ></b-alert
              >
              <b-card
                class="mb-3"
                :class="{
                  'border-danger':
                    parseInt(quantity[index]) > parseInt(item.quantity)
                }"
                v-for="(item, index) in items"
                :key="index"
              >
                <b-card-body class="p-0">
                  <b-row class="align-items-center">
                    <b-col cols="2">
                      <b-img
                        thumbnail
                        fluid
                        :src="thumbImagePath(item.thumbImagePath)"
                        style="max-height: 150px"
                      />
                    </b-col>
                    <b-col cols="5">
                      <div>
                        <strong>{{ item.brand }} {{ item.code }}</strong>
                      </div>
                      <div>
                        <small>
                          {{ item.name }}
                        </small>
                      </div>
                      <div>
                        EAN: <em>{{ item.barcode }}</em>
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        type="number"
                        min="1"
                        class="text-center font-weight-bold"
                        size="lg"
                        @keyup.enter="editItem()"
                        v-model="quantity[index]"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-progress
                        :max="item.quantity"
                        height="3rem"
                        show-progress
                        :animated="
                          parseInt(quantity[index]) !== parseInt(item.quantity)
                        "
                        :variant="
                          parseInt(quantity[index]) > parseInt(item.quantity)
                            ? 'danger'
                            : 'success'
                        "
                      >
                        <b-progress-bar :value="quantity[index]">
                          <span
                            ><strong
                              >{{ quantity[index] }} /
                              {{ item.quantity }}</strong
                            ></span
                          >
                        </b-progress-bar>
                      </b-progress>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-overlay :show="transaction" no-wrap fixed>
        <template #overlay>
          <div class="bg-primary text-center p-4 text-light rounded">
            <b-spinner style="width: 6rem; height: 6rem;"></b-spinner>
            <div class="mb-3">
              <font-awesome-icon icon="clipboard-check" size="lg" />
              {{ transactionDetail }}
            </div>
          </div>
        </template>
      </b-overlay>
    </b-container>
    <b-modal id="pdf-print-label" size="lg" hide-footer>
      <div class="d-block" style="height: 500px;">
        <embed
          v-if="labelPdfBody"
          :src="'data:application/pdf;base64,' + labelPdfBody"
          type="application/pdf"
          style="overflow: auto; width: 100%; height: 100%;"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "shipment",
  data: function() {
    return this.initialState();
  },
  computed: {
    confirmButtonDisabled: function() {
      let result = [];
      this.parcelElements.forEach(function(element) {
        let parcelElements = [
          element.weight,
          element.length,
          element.width,
          element.height
        ];
        if (parcelElements.every(value => value > 0)) result.push(true);
        else result.push(false);
      });
      if (result.length === 0) return true;
      return !result.every(value => value === true);
    }
  },
  watch: {
    quantity: function() {
      this.checkSuccess();
      this.checkFail();
    },
    labelPdf: function(value) {
      localStorage.setItem("labelPdf", value);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("workStationNumber") === null)
      next({ name: "work-station" });
    else next();
  },
  methods: {
    initialState: function() {
      return {
        step: null,
        success: false,
        fails: [],
        orderId: this.orderId ?? "",
        transaction: false,
        transactionDetail: "",
        barcode: "",
        barcodeList: [],
        incorrectBarcodes: [],
        quantity: [],
        items: null,
        parcels: null,
        boxScan: null,
        parcelElements: [],
        orderNote: null,
        response: {},
        address: {},
        printSuccess: null,
        errorResponse: null,
        errorShipment: null,
        labelPdf: localStorage.getItem("labelPdf") === true,
        labelPdfBody: null
      };
    },
    checkFail: function() {
      let result = [];
      let that = this;
      if (!this.items) return false;
      this.items.forEach(function(element, index) {
        if (parseInt(element.quantity) < parseInt(that.quantity[index]))
          result[index] = {
            name: that.items[index].brand + " " + that.items[index].code,
            quantity: that.quantity[index] - that.items[index].quantity
          };
      });
      this.fails = result;
    },
    checkSuccess: function() {
      let result = true;
      let that = this;
      if (!this.items) return false;
      this.items.forEach(function(element, index) {
        if (parseInt(element.quantity) !== parseInt(that.quantity[index]))
          result = false;
      });
      this.success = result;
    },
    addBox: function() {
      let scan = this.boxScan.split("x");
      if (scan.length < 3) return false;
      this.boxScan = "";
      this.addParcel(
        null,
        parseInt(scan[0]),
        parseInt(scan[1]),
        parseInt(scan[2])
      );
      this.addParcelNumber();
    },
    addParcel: function(
      weight = null,
      length = null,
      width = null,
      height = null
    ) {
      this.parcelElements.push({
        weight: weight,
        length: length,
        width: width,
        height: height
      });
    },
    addParcelNumber: function() {
      if (this.parcels === null) this.parcels = 0;
      this.parcels = parseInt(this.parcels) + 1;
    },
    removeParcelNumber: function() {
      if (this.parcels === null) this.parcels = 0;
      this.parcels = parseInt(this.parcels) - 1;
      if (this.parcels < 1) this.parcels = null;
    },
    copyParcel(index) {
      let parcelElement = this.parcelElements[index];
      this.addParcel(
        parcelElement.weight,
        parcelElement.length,
        parcelElement.width,
        parcelElement.height
      );
      this.addParcelNumber();
    },
    removeParcel: function(index) {
      this.parcelElements.splice(index, 1);
      this.removeParcelNumber();
    },
    insertParcel: function() {
      this.addParcel();
      this.addParcelNumber();
    },
    addItem: function() {
      if (this.barcode === "") return false;
      let index = this.arraySearch(this.barcodeList, this.barcode);
      if (index === false) {
        this.incorrectBarcodes.push(this.barcode);
        this.barcode = "";
        return false;
      }
      this.barcode = "";
      if (!this.quantity[index]) this.quantity[index] = 1;
      else this.quantity[index]++;
      this.checkSuccess();
      this.checkFail();
    },
    editItem: function() {
      this.$refs.barcode.$el.focus();
    },
    editSize: function() {
      this.$refs.boxScan.$el.focus();
    },
    editOrder: function() {
      this.$refs.order.$el.focus();
    },
    getOrder: function() {
      this.reset();
      this.step = 1;
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}shipment/${this.orderId}`)
        .then(res => {
          if (
            res.data.orderStatus === "shipped" ||
            res.data.orderStatus === "voided"
          )
            this.step = 5;
          if (res.data.orderStatus === "shipped")
            this.errorShipment = "La spedizione è già stata processata.";
          if (res.data.orderStatus === "voided")
            this.errorShipment = "L'ordine è stato annullato, NON SPEDIRE!";
          this.orderNote = res.data.note;
          this.items = this.cleanItem(res.data.item);
          this.barcodeList = this.arrayColumn(this.items, "barcode");
          this.address = res.data.address;
          if (this.address.length === 0) {
            this.errorResponse = "Indirizzo mancante!";
            this.items = [];
          }
        })
        .catch(err => {
          this.error = err;
        });
    },
    cleanItem: function(item) {
      item.forEach(function(element, index) {
        if (!element.itemId && element.price < 0) item.splice(index, 1);
      });
      return item;
    },
    arrayColumn: function(array, columnName) {
      return array.map(function(value) {
        return value[columnName];
      });
    },
    arraySearch: function(array, value) {
      for (let i = 0; i < array.length; i++) if (array[i] === value) return i;
      return false;
    },
    resetQuantity: function() {
      let that = this;
      this.fails.forEach(function(element, index) {
        if (parseInt(element.quantity) < parseInt(that.quantity[index]))
          that.quantity[index] = null;
      });
      this.fails = [];
    },
    resetAllQuantity: function() {
      this.quantity = [];
      this.fails = [];
      this.resetIncorrectBarcodes();
    },
    resetIncorrectBarcodes: function() {
      this.incorrectBarcodes = [];
    },
    getPrinterConfiguration: function() {
      return {
        printServerIndex: localStorage.getItem("printServerIndex"),
        workStationNumber: localStorage.getItem("workStationNumber")
      };
    },
    sendData: function() {
      this.transaction = true;
      this.transactionDetail = "Invio dei dati al corriere...";
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}shipment/dispatch`, {
          orderId: this.orderId,
          parcels: this.parcelElements,
          printerConfiguration: this.getPrinterConfiguration(),
          pdf: this.labelPdf
        })
        .then(res => {
          this.response = res.data;
          this.transaction = false;
          if (this.response.success) {
            this.step = 3;
            this.errorResponse = null;
          } else this.errorResponse = this.response.error;
        })
        .catch(err => {
          this.error = err;
        });
    },
    close: function() {
      this.transaction = true;
      this.transactionDetail = "Chiusura spedizione in corso...";
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}shipment/close`, {
          orderId: this.orderId
        })
        .then(res => {
          if (res.data) this.step = 4;
          setTimeout(() => {
            this.reset();
            this.orderId = "";
            this.editOrder();
          }, 3000);
          this.transaction = false;
        })
        .catch(err => {
          this.error = err;
        });
    },
    printLabel: function() {
      if (this.labelPdf) {
        this.labelPdfBody = this.response.printData;
        this.$bvModal.show("pdf-print-label");
        this.printSuccess = true;
      } else
        this.$http
          .post(this.response.printServer, {
            printer: this.response.printer,
            data: this.response.printData
          })
          .then(res => {
            this.printSuccess = !!res.data.status;
          })
          .catch(err => {
            this.error = err;
          });
    },
    reset: function() {
      Object.assign(this.$data, this.initialState());
    },
    deleteShipment: function() {
      this.transaction = true;
      this.transactionDetail = "Eliminazione spedizione in corso...";
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}shipment/delete`, {
          orderId: this.orderId
        })
        .then(res => {
          this.response = res.data;
          this.transaction = false;
          if (this.response.success) {
            this.step = 2;
            this.printSuccess = null;
            this.errorResponse = null;
          } else this.errorResponse = this.response.error;
        })
        .catch(err => {
          this.error = err;
        });
    },
    thumbImagePath: function(path) {
      return path ? path : process.env.VUE_APP_IMAGE_NOT_AVAILABLE;
    }
  }
};
</script>

<style>
.text-pre-line {
  white-space: pre-line;
}
</style>
