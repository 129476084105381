<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row>
        <b-col class="mb-3 text-right">
          <b-link variant="primary" :to="{ name: 'shipment' }">Spedisci</b-link>
        </b-col>
      </b-row>
      <b-col>
        <b-row>
          <b-button variant="danger" block size="lg" @click="showModal"
            >Stampa Manifest <font-awesome-icon icon="download"
          /></b-button>
        </b-row>
        <b-row>
          <h1 class="mt-2">
            <b-badge variant="primary">{{ totalParcels }}</b-badge> Colli ({{
              shipmentList.length
            }}
            spedizioni)
          </h1>
          <b-table striped hover :items="shipmentList"></b-table>
        </b-row>
      </b-col>
    </b-container>
    <b-modal ref="manifest-confirm" hide-footer title="Stampa Manifest">
      <p class="my-4">Confermi la consegna dei colli al corriere?</p>
      <p class="text-right">
        <b-button class="m-1" @click="hideModal">Annulla</b-button>
        <b-button class="m-1" variant="danger" @click="manifestDownload"
          >Conferma</b-button
        >
        <b-progress
          v-if="download"
          value="100"
          variant="info"
          show-progress
          striped
          animated
        />
      </p>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "ShipmentsList",
  data() {
    return {
      shipmentList: [],
      download: false
    };
  },
  mounted() {
    this.setShipmentList();
  },
  computed: {
    totalParcels() {
      let result = 0;
      this.shipmentList.forEach(function(element) {
        result += parseInt(element.parcelsNumber);
      });
      return result;
    }
  },
  methods: {
    setShipmentList: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}shipment/list`)
        .then(res => {
          this.shipmentList = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    manifestDownload: function() {
      window.location.href = `${process.env.VUE_APP_API_ADMIN}manifest_brt.php?confirm=1`;
      this.download = true;
      setTimeout(() => this.hideModal(), 2000);
    },
    showModal() {
      this.$refs["manifest-confirm"].show();
    },
    hideModal() {
      this.$refs["manifest-confirm"].hide();
      this.download = false;
    }
  }
};
</script>
