<template>
  <div id="app" class="d-flex flex-column" style="min-height: 100vh">
    <div class="sidebar bg-gradient-primary" v-if="header">
      <sidebar />
    </div>
    <div class="main">
      <navbar v-if="header" />
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Navbar";

export default {
  name: "app",
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return {
      header: this.$store.getters.getHeader
    };
  },
  watch: {
    $route() {
      this.header = this.$store.getters.getHeader;
    }
  }
};
</script>

<style lang="scss">
@import "styles/_custom.scss";

html,
body {
  font-family: "Ubuntu", sans-serif;
}

/* General classes */
.main-background {
  background: rgba(255, 153, 0, 1);
}

.main-border {
  border: rgba(255, 153, 0, 1);
}

.main-background-transparent {
  background: rgba(255, 153, 0, 0.7);
}

.main-color {
  color: rgba(255, 153, 0, 1);
}

.hidden-element {
  visibility: hidden;
}

.shadow {
  z-index: 10;
  -webkit-box-shadow: 0 0.15rem 0.8rem 0 rgba(58, 59, 69, 0.3) !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28) !important;
}

.card-img-top {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
  .card-img-top {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.manufacturer-img-top {
  height: 5vh;
  object-fit: contain;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky:before,
.sticky:after {
  content: "";
  display: table;
}

/* Animations */
.slide-in-top {
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-23 16:52:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.img-top-main {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

.img-top-main-alt {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.img-top-alt {
  width: 100%;
  height: 75px;
  object-fit: contain;
}

.sidebar {
  display: none;
}

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    width: 220px;
  }
}

@media (min-width: 768px) {
  .main {
    padding-left: 220px;
  }
}

.bg-gradient-primary {
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
}
</style>
